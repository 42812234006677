export default ({get, post}) => ({
  login(user) {
    return post('users/login', user);
  },
  getMenu() {
    return get('users/menu');
  },
  getRoles() {
    return get('users/roles');
  },
  getUsers(params) {
    return get('users/all' + '?' + (new URLSearchParams(params).toString()));
  },
  saveUser(user) {
    return post('users/save', user)
  },
  // getAll() {
  //   // return new Promise((resolve, reject) => {
  //   //   Http.get('/projects/edit/' + project_id).then(resp => {
  //   //     commit(DESIGNER_PROJECT_REQUEST, resp.data)
  //   //     resolve(resp.data);
  //   //   })
  //   //     .catch(err => {
  //   //       console.log('Error while fetch project ' + err)
  //   //       reject(err);
  //   //     })
  //   // });
  //   // const params = { ajax: 1 }
  //
  //   return get(`/colors/all`)
  // },
  // getHistory({ report_id }) {
  // 	const params = { ajax: 1, username: report_id }
  // 	return get('/ajax/tiktokHistory', { params, baseURL: isENV('production') ? '/' : '/auditor' })
  // },
  // getRating(params) {
  // 	params.ajax = 1
  // 	return get('/auditor/tiktok/getRankingData', { params, baseURL: '/' })
  // },
})
