<template>
  <div class="wrapper">
<!--    <v-progress-linear-->
<!--      indeterminate-->
<!--      color="blue-grey darken-1"-->
<!--      v-if="isLoading == true"-->
<!--    ></v-progress-linear>-->
    <slot/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  components: {
    // ProgressBar: () => import('@/components/elements/ProgressBar'),
  },
  computed: {
    ...mapState('app', ['isLoading']),
  },
}
</script>

<style lang="scss" scoped>

</style>
