<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Selfie Mosaic
            </v-list-item-title>
            <v-list-item-subtitle>
              app
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            :to="{path: item.link}"
          >
            <v-list-item-icon>
              <v-icon style="font-size: 16px;">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>


    <v-app-bar
      app
      color="blue-grey"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-progress-linear
        indeterminate
        color="blue-grey darken-1"
        v-if="isLoading == true"
      ></v-progress-linear>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      drawer: null,
    }
  },
  components: {},
  computed: {
    ...mapState('app', ['menu', 'isLoading']),
  },
  methods: {
    ...mapActions('app', ['loadMenu']),
  },
  async created() {
    await this.loadMenu()
  },
}
</script>
