import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.state.app.token) {
    next();
    return;
  }
  next("/login");
};

const _import = name => () => import('@/views/' + name + '.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/projects/List.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: 'admin-layout'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/colors',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/colors/List.vue'),
    meta: {
      layout: 'admin-layout'
    }
  },
  {
    path: '/users',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/users/List.vue'),
    meta: {
      layout: 'admin-layout'
    }
  },
  {
    path: '/project/design/:id',
    beforeEnter: ifAuthenticated,
    name: 'admin_project_design',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('@/views/projects/Design.vue'),
  },
  {
    path: '/project/preview/:id',
    beforeEnter: ifAuthenticated,
    name: 'admin_project_preview',
    meta: {
      layout: 'admin-layout'
    },
    component: () => import('@/views/projects/Preview.vue'),
  },
  {
    path: '/project/print/:id',
    // beforeEnter: ifAuthenticated,
    name: 'admin_project_print',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('@/views/projects/Print'),
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
