// import Vue from 'vue'
// import Vuex from 'vuex'
// import auth from "./modules/auth";
// import admin from './modules/admin';
// import designer from './modules/designer';
//
// Vue.use(Vuex);
//
// export default new Vuex.Store({
//   state: {
//     layout: 'default-layout',
//   },
//   mutations: {
//     setLayout(state, layoutId) {
//       state.layout = layoutId;
//     },
//   },
//   getters: {
//     currentLayout(state) {
//       return state.layout;
//     }
//   },
//   actions: {
//     init({state, dispatch}) {
//     }
//   },
//   modules: {
//     admin,
//     auth,
//     designer
//   },
//   // plugins: [undoRedoPlugin],
// })
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modules = (() => {
  const requireComponent = require.context('./modules', true, /^[^_].+\.js$/i)

  return requireComponent
    .keys()
    .reduce((_modules, filename) => {
      const componentConfig = requireComponent(filename)

      const name = /[^.](.+).js$/.exec(filename)[1]

      _modules[name] = componentConfig.default || componentConfig

      return _modules
    }, {})

})()

const plugins = [
  // createPersistedState({
  //   paths: ['storage'],
  // }),
]

export default new Vuex.Store({modules, plugins})
