import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Api from './api'
import VueBus from 'vue-bus'
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment'
import ImageUploader from 'vue-image-upload-resize';
import VueFilterPluralize from '@vuejs-community/vue-filter-pluralize';

import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';
import AdminLayout from './layouts/Admin.vue';
import EmptyLayout from './layouts/Empty.vue';
import i18n from './i18n'
import '@/plugins';


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0bd91087dca44623888daa4b7debb9b8@o251444.ingest.sentry.io/1437626',
    integrations: [new VueIntegration({Vue, attachProps: true})],
  });
}


Vue.use(ImageUploader);
Vue.use(VueBus)
Vue.use(Api);
Vue.use(VueFilterPluralize);


Vue.config.productionTip = true;
Vue.prototype.moment = moment

Vue.component('admin-layout', AdminLayout);
Vue.component('default-layout', EmptyLayout);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n,
  components: {App}
}).$mount('#app');
