import axios from 'axios'
import store from '@/store';

export default new class Api {
  instance = axios.create({
    //baseURL: (process.env.NODE_ENV == 'development') ? 'http://localhost:5000/api' : '/api',
    baseURL: '/api',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })


  constructor() {
    this.instance.interceptors.request.use((config) => {
      config.headers.common['Authorization'] = 'Bearer ' + store.state.app.token;
      // console.log(store.state.app.token)
      return config
    });

    // Register services
    const requireService = require.context('./service', false, /.js$/)

    requireService
      .keys()
      .forEach(filename => {
        const name = /[^.](.+).js$/.exec(filename)[1]

        this[name] = requireService(filename).default(this.instance)
      })

    this.cancelToken = axios.CancelToken

    this.intercept(({response}) => {
      // request.use(config => {
      // 	config.guid = GUID()
      // 	store.commit('app/startAsyncOperation', config.guid)

      // 	const { token } = store.state.auth

      // 	if(token) {
      // 		config.headers['Authorization'] = `Bearer ${token.Access_token}`
      // 	}

      // 	return config
      // })

      response.use(response => {
        return response.data.data;
      }, error => {
        console.log(error);
        switch (error.response.status) {
          case 401:
            console.log('You have no access to requested document');
            // this.redirectTo(document, '/login')
            break;
          case 404:
            this.redirectTo(document, '/404')
            break;
          default:
            console.log(error);
            // alert(error);
            // this.redirectTo(document, '/500')
            break;
        }


        return Promise.reject(error.response)
      })
    })
  }

  intercept(fn) {
    fn(this.instance.interceptors)
  }

  install(Vue) {
    Vue.prototype.$api = this
  }
}
