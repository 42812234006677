export default ({get, post}) => ({
  getProject({project_id}) {
    return get(`/projects/edit/${project_id}`)
  },
  getProjectWithdrawInfo({project_id}) {
    return get(`/projects/withdraw-info/${project_id}`)
  },
  getMy({page}) {
    return get(`/projects/my?page=${page}`)
  },
  copy({project_id}) {
    return get(`/projects/copy/${project_id}`)
  },
  uploadFile(fileData) {
    return post('projects/upload-file', fileData)
  },
  editFile(payload) {
    return post('projects/edit-file', payload)
  },
  save(project) {
    return post('projects/save', project)
  },
  create() {
    return post('projects/create')
  },
  exportArchive({project_id}) {
    return post(`projects/export/${project_id}`)
  },
  // getHistory({ report_id }) {
  // 	const params = { ajax: 1, username: report_id }
  // 	return get('/ajax/tiktokHistory', { params, baseURL: isENV('production') ? '/' : '/auditor' })
  // },
  // getRating(params) {
  // 	params.ajax = 1
  // 	return get('/auditor/tiktok/getRankingData', { params, baseURL: '/' })
  // },
})
