import api from "@/api";
import Vue from 'vue'

export const PIXELS_PER_ONE_BRICK = 10;

export const DesignTool = {
  Brush: 'brush',
  Fill: 'fill',
  Replace: 'replace',
  Erase: 'erase',
}

const defaultLayer = () => {
  let uniqueId = Math.random().toString(36).substring(2) + Date.now().toString(36);

  return {
    id: uniqueId,
    visible: true,
    settings: {
      contrast: 0,
      brightness: 0,
      saturation: 0,
      hue: 0,
      algorithm: '2020_12_01',
      colors_count: 12,
      unsharp_mask_kernel_size: 7,
      unsharp_mask_sigma_x10: 10,
      constraint_prc: 120
    },
  };
}

const getDefaultState = () => {
  return {
    zoom: 1.0,
    projectHistory: [],
    projectHistoryIndex: 0,
    project: {
      id: 0,
      title: 'new',
      meta: {
        canvas_width: 2,
        canvas_height: 2,
        plate_size: 32,
        palette: '',
      },
      layers: [defaultLayer()]
    },

    layerIndex: 0,
    currentColor: null,
    tool: DesignTool.Brush,
  }
}
const state = getDefaultState();

const actions = {
  initClear({commit}) {
    commit('initClear')
  },
  async loadProject({commit}, project_id) {
    const response = await api.projects.getProject({project_id: project_id})
    commit('setProject', response)
    return state.project
  },
  zoom({commit}, zoomFactor) {
    state.zoom = zoomFactor;
  },
  zoomIn({commit}) {
    state.zoom = state.zoom * 1.05;
  },
  zoomOut({commit}) {
    state.zoom = state.zoom * 0.95;
  },
  zoomReset({commit}) {
    state.zoom = 1.0
  },
  async uploadFile({commit, dispatch}, data) {
    let formData = new FormData;
    formData.append('file', data.output);
    formData.append('project_id', data.project_id);

    const response = await api.projects.uploadFile(formData)
    commit('setUploadedFile', response)
    return response
  },
  async editFile({commit, dispatch}, options) {
    commit('historyAdd')

    options = options || {};
    let layerIndex = (options.layerIndex != undefined) ? options.layerIndex : state.layerIndex;
    let layer = state.project.layers[layerIndex];

    if (layer.file_id == undefined) {
      console.log('FileId is null in layer', layer, 'for index', layerIndex);
      return
    }
    let payload = {
      file_id: layer.file_id,

      contrast: layer.settings.contrast,
      brightness: layer.settings.brightness,
      saturation: layer.settings.saturation,
      hue: layer.settings.hue,
      colors_count: layer.settings.colors_count,
      algorithm: layer.settings.algorithm,
      unsharp_mask_kernel_size: layer.settings.unsharp_mask_kernel_size,
      unsharp_mask_sigma_x10: layer.settings.unsharp_mask_sigma_x10,

      constraint_prc: layer.settings.constraint_prc,

      canvas_width: state.project.meta.canvas_width,
      canvas_height: state.project.meta.canvas_height,
      plate_size: state.project.meta.plate_size,
      palette: state.project.meta.palette,
    };

    const resp = await api.projects.editFile(payload)
    commit('editFile', {
      layerIndex: layerIndex,
      file_id: payload.file_id,
      data: resp.pixel_data,
      preview_url: resp.preview_url
    })
  },
  // [DESIGNER_PROJECT_SAVE]: ({commit, dispatch}) => {
  //   commit(DESIGNER_CHANGE_LOADING_STATE, true);
  //
  //   let params = state.project;
  //
  //   Http.post('/projects/save', params).then(resp => {
  //     commit(DESIGNER_CHANGE_LOADING_STATE, false);
  //   }).catch(err => {
  //     commit(DESIGNER_CHANGE_LOADING_STATE, true);
  //     console.log(err);
  //     // reject(err);
  //   })
  // },
  // [DESIGNER_PROJECT_CREATE]: ({commit, dispatch}) => {
  //   return new Promise((resolve, reject) => {
  //     Http.post('/projects/create').then(resp => {
  //       resolve(resp.data.project_id)
  //     }).catch(err => {
  //       reject(err);
  //     });
  //   });
  // },
  //


};

const mutations = {
  initClear(state) {
    Object.assign(state, getDefaultState())
  },
  setProject(state, response) {
    //   if (project.project.meta.palette == undefined) {
    //     project.project.meta.palette = state.palettes[0]._id;
    //   }
    state.project = response.project
    // state.isProjectLoaded = true
    //   state.isProjectLoaded = true;
    //   state.colors = state.all_colors.filter(function (e) {
    //     return e.palettes.includes(state.project.meta.palette);
    //   });
  },
  setColor(state, color) {
    state.currentColor = color
  },
  setCurrentLayer: (state, layer_index) => {
    state.layerIndex = layer_index;
  },
  toggleLayerVisibility: (state, layer_index) => {
    state.project.layers[layer_index].visible = !state.project.layers[layer_index].visible;
  },
  setTool(state, tool) {
    state.tool = tool
  },
  addLayer(state) {
    let size = state.project.meta.canvas_height * state.project.meta.canvas_width * state.project.meta.plate_size * state.project.meta.plate_size;

    let newLayer = defaultLayer()
    newLayer.data = Array.from({length: size}).fill(0)
    state.project.layers.push(newLayer)
    state.layerIndex = state.project.layers.length - 1;
  },
  removeLayer(state, layer_index) {
    if (state.project.layers.length === 1) {
      return;
    }

    if (state.project.layers.length > 1 && layer_index > 0) {
      state.layerIndex = layer_index - 1;
    }
    state.project.layers.splice(layer_index, 1);
  },
  changeLayerIndex(state, options) {
    let oldIndex = options.direction === 'up' ? options.layerIndex - 1 : options.layerIndex;
    let rows = [state.project.layers[oldIndex], state.project.layers[oldIndex + 1]];
    state.project.layers.splice(oldIndex, 2, rows[1], rows[0]);
  },
  setUploadedFile(state, file_info) {
    Vue.set(state.project.layers[state.layerIndex], 'preview_url', file_info.preview_url);
    state.project.layers[state.layerIndex].file_id = file_info.file_id;
    state.project.layers[state.layerIndex].file_name = file_info.file_name;
  },
  // [DESIGNER_CHANGE_LOADING_STATE]: (state, isProgress) => {
  //   state.isProgress = isProgress;
  // },
  editFile(state, file_info) {
    // console.log(state.project.layers[file_info.layerIndex], file_info.preview_url);
    Vue.set(state.project.layers[file_info.layerIndex], 'preview_url', file_info.preview_url);
    state.project.layers[file_info.layerIndex].data = file_info.data;
  },
  // editLayout(state, file_info) {
  //   // Vue.set(state.project.layers[state.layerIndex], 'preview_url', file_info.preview_url);
  //   //   state.project.layers[state.layerIndex].data = file_info.data;
  // },
  historyAdd(state) {
    if (state.projectHistoryIndex < state.projectHistory.length - 1) {
      state.projectHistory = state.projectHistory.slice(0, state.projectHistoryIndex);
    }

    state.projectHistoryIndex += 1;
    const clone = JSON.parse(JSON.stringify(state.project));
    state.projectHistory.push(clone)

    if (state.projectHistory.length > 10) {
      state.projectHistory = state.projectHistory.slice(1);
      state.projectHistoryIndex -= 1;
    }
  },
  historyUndo(state) {
    state.projectHistoryIndex -= 1
    let undo_state = state.projectHistory[state.projectHistoryIndex];
    if (undo_state.layers.length < state.project.layers.length) {
      state.layerIndex -= 1;
    }
    state.project.layers = undo_state.layers
  },
  historyRedo(state) {
    state.projectHistoryIndex += 1
    let undo_state = state.projectHistory[state.projectHistoryIndex];
    state.project.layers = undo_state.layers
  }
};

const getters = {
  currentLayer: state => state.project.layers[state.layerIndex],
  canvasSize: state => {
    return {
      width: state.project.meta.canvas_width * PIXELS_PER_ONE_BRICK * state.project.meta.plate_size,
      height: state.project.meta.canvas_height * PIXELS_PER_ONE_BRICK * state.project.meta.plate_size
    }
  },
  canvasSizeWithZoom: state => {
    return {
      width: state.project.meta.canvas_width * PIXELS_PER_ONE_BRICK * state.project.meta.plate_size * state.zoom,
      height: state.project.meta.canvas_height * PIXELS_PER_ONE_BRICK * state.project.meta.plate_size * state.zoom
    }
  },
  canUndo: state => state.projectHistoryIndex > 0,
  // canRedo: state => state.projectHistory.length - 1 - state.projectHistoryIndex > 0,
  canRedo: state => state.projectHistory.length > state.projectHistoryIndex + 1,
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
