<template>
  <v-app>
    <component :is="layout">
      <router-view/>
      <!-- set progressbar -->

    </component>
  </v-app>
</template>

<style>
@import './assets/styles/sm.css';
</style>

<script>
export default {
  // mounted() {
  //   //  [App.vue specific] When App.vue is finish loading finish the progress bar
  //   this.$Progress.finish()
  // },
  // created() {
  //   //  [App.vue specific] When App.vue is first loaded start the progress bar
  //   this.$Progress.start()
  //   //  hook the progress bar to start before we move router-view
  //   this.$router.beforeEach((to, from, next) => {
  //     //  does the page we want to go to have a meta.progress object
  //     if (to.meta.progress !== undefined) {
  //       let meta = to.meta.progress
  //       // parse meta tags
  //       this.$Progress.parseMeta(meta)
  //     }
  //     //  start the progress bar
  //     this.$Progress.start()
  //     //  continue to next page
  //     next()
  //   })
  //   //  hook the progress bar to finish after we've finished moving router-view
  //   this.$router.afterEach((to, from) => {
  //     //  finish the progress bar
  //     this.$Progress.finish()
  //   })
  // },
  computed: {
    layout() {
      // return this.$store.getters.currentLayout
      return this.$route.meta.layout || 'default-layout'
    }
  },
  components: {
    // 'admin-layout': AdminLayout,
    // 'simple-layout': SimpleLayout
    // define as many layouts you want for the application
  }
}
</script>
