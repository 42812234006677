import api from '@/api'

const state = {
  menu: null,
  token: localStorage.getItem("user-token") || "",
  isLoading: true,
  roles: []
};

const getters = {
  // isAuthenticated: state => !!state.token,
  // authStatus: state => state.status
  // isLoading: state => state.loading,
};


const actions = {
  async login({commit, state}, user) {
    const response = await api.users.login(user);
    commit('setCurrentUser', response);
    return response
  },
  async loadMenu({state, dispatch, commit}, reload) {
    if (!state.menu || reload) {
      const response = await api.users.getMenu()

      commit('setMenu', response.menu)
      commit('setRoles', response.roles)

      return response.menu
    } else {
      return state.menu
    }
  },
  setLoading({commit, state}, isLoadingStatus) {
    commit('setLoading', isLoadingStatus);
  },
};


const mutations = {
  setCurrentUser(state, response) {
    state.token = response.access_token
    localStorage.setItem("user-token", response.access_token);
  },
  setMenu(state, menu) {
    state.menu = menu
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setLoading(state, isLoadingStatus) {
    state.isLoading = isLoadingStatus
  }
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
