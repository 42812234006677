export default ({get, post}) => ({
  getAll() {
    // return new Promise((resolve, reject) => {
    //   Http.get('/projects/edit/' + project_id).then(resp => {
    //     commit(DESIGNER_PROJECT_REQUEST, resp.data)
    //     resolve(resp.data);
    //   })
    //     .catch(err => {
    //       console.log('Error while fetch project ' + err)
    //       reject(err);
    //     })
    // });
    // const params = { ajax: 1 }

    return get(`colors/all`)
  },
  save(color) {
    return post('colors/save', color)
  },
  savePalette(palette) {
    return post('colors/save-palette', palette)
  },
})
